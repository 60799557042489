<template>
  <v-container v-if="pageLoading" class="bg-color-theme px-3">
    <v-skeleton-loader type="list-item-two-line, card"></v-skeleton-loader>
  </v-container>

  <v-container v-else class="bg-color-theme px-3">
    <v-card>
      <v-card-title class="text-center fw-400 font-18px justify-center py-3">{{
        yearMonth
      }}</v-card-title>
      <v-divider class="pb-7"></v-divider>
      <validation-observer :ref="'observer'" v-slot="{ invalid }">
        <v-form
          @submit.prevent="submit"
          lazy-validation
          autocomplete="off"
          ref="form"
        >
          <p class="pl-16 font-16px mb-1">
            {{
              previousFiscalMonth
                ? previousFiscalMonth
                : getPreviousFiscalMonth
            }}の棚卸額
          </p>
          <div class="d-flex justify-end font-12px text-67 pr-14 mb-4">
            ※ 先月入力データ
          </div>
          <v-row
            v-for="field in fieldDisabled"
            :key="field.name"
            class="ma-0 pl-15 mr-13"
            no-gutters
            justify="center"
          >
            <v-col cols="4" class="font-14px text-33 pt-2">
              {{ field.label }}
            </v-col>
            <template v-if="field.type == 'text'">
              <v-col class="pa-0" cols="7">
                <InputCurrency
                  :disabled="field.disabled"
                  :placeholder="field.placeholder"
                  background-color="#EAEDF0"
                  dense
                  solo
                  v-model.number="field.value"
                  :error-messages="errors"
                  autocomplete="chrome-off"
                  :hint="field.hint"
                  persistent-hint
                  type="number"
                  class="cost-field"
                  :max="12"
                >
                </InputCurrency>
              </v-col>
            </template>
          </v-row>
          <p class="pl-16 font-16px mb-5">
            {{ currentFiscalMonth ? currentFiscalMonth : yearMonth }}の棚卸額
          </p>
          <v-row
            v-for="field in fieldEnabled"
            :key="field.name"
            class="ma-0 pl-15 mr-13"
            no-gutters
            justify="center"
          >
            <v-col cols="4" class="font-14px text-33 pt-2">
              {{ field.label }}
            </v-col>
            <template v-if="field.type == 'text'">
              <v-col class="pa-0" cols="7">
                <validation-provider
                  v-slot="{ errors }"
                  :rules="field.rules"
                  :name="field.name"
                >
                  <InputCurrency
                    :placeholder="field.placeholder"
                    background-color="#EAEDF0"
                    dense
                    solo
                    v-model.number="field.value"
                    :error-messages="errors"
                    autocomplete="chrome-off"
                    :hint="field.hint"
                    persistent-hint
                    type="number"
                    class="cost-field"
                    :max="12"
                  >
                  </InputCurrency>
                </validation-provider>
              </v-col>
            </template>
          </v-row>
          <v-row class="ma-0 mt-3 px-4 pb-7">
            <v-col
              class="px-0 pt-0 pb-0 d-flex justify-space-between"
              cols="12"
              md="12"
            >
              <v-btn
                type="button"
                color="white"
                class="primary--text border-primary"
                min-width="146"
                @click.stop="$router.go(-1)"
              >
                キャンセル
              </v-btn>
              <v-btn
                type="submit"
                color="primary"
                min-width="146"
                :loading="loading"
                :disabled="invalid"
              >
                保存する
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";
import InputCurrency from "@/components/ui/InputCurrency";
export default {
  name: "InventoryManagementEdit",
  components: { InputCurrency },
  computed: {
    ...mapGetters(["getInventoryByThisMonth", "getInventoryByPreMonth"]),
    getPreviousFiscalMonth() {
      if (this.yearMonth) {
        return dayjs(this.yearMonth)
          .subtract(1, "months")
          .format("YYYY/MM");
      }
      return "";
    }
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
    submit() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }

        let this_month_params = {};
        let shop_id = this.$store.getters.getFirstShopObject.id;
        this.fieldEnabled.forEach(field => {
          if (field) {
            if (field.value) {
              this_month_params[field.name] = field.value;
            }
          }
        });

        //For This Month Inventory
        if (this.getInventoryByThisMonth.length > 0) {
          let params = {};
          params = this_month_params;
          let inventory_id = this.getInventoryByThisMonth[0].id;
          this.$store
            .dispatch("INVENTORY_UPDATE", { params, shop_id, inventory_id })
            .then(
              response => {
                if (response.status === 200) {
                  this.$router.push({ name: "InventoryManagementList" });
                  this.$store.commit("showAlert", {
                    text: "編集内容を保存しました。",
                    successStatus: "info"
                  });
                }
              },
              error => {
                if (error?.data?.data?.message) {
                  this.$store.dispatch("SHOW_ALERT", {
                    text: error?.data?.data?.message,
                    successStatus: "info"
                  });
                }
              }
            );
        } else {
          let params = {};
          params = this_month_params;
          params["year_month"] = this.yearMonth;
          this.$store.dispatch("INVENTORY_REGISTER", { params, shop_id }).then(
            response => {
              if (response.status === 200) {
                this.$router.push({ name: "InventoryManagementList" });
                this.$store.commit("showAlert", {
                  text: "編集内容を保存しました。",
                  successStatus: "info"
                });
              }
            },
            error => {
              if (error?.data?.data?.message) {
                this.$store.dispatch("SHOW_ALERT", {
                  text: error?.data?.data?.message,
                  successStatus: "info"
                });
              }
            }
          );
        }
      });
    },
    getDataFromApi() {
      let this_month_param = {
        shop_id: this.$store.getters.getFirstShopObject.id, //Shop Id
        year_month: this.yearMonth
      };
      this.$store
        .dispatch("INVENTORY_GET_BY_THIS_MONTH", this_month_param)
        .finally(() => {
          this.pageLoading = false;
          this.patchEditThisFormValues();
        });

      let pre_month_param = {
        shop_id: this.$store.getters.getFirstShopObject.id, //Shop Id
        year_month: this.getPreviousFiscalMonth
      };

      this.$store
        .dispatch("INVENTORY_GET_BY_PRE_MONTH", pre_month_param)
        .finally(() => {
          this.pageLoading = false;
          this.patchEditPreFormValues();
        });
    },
    patchEditThisFormValues() {
      this.fieldEnabled.forEach(field => {
        if (this.getInventoryByThisMonth.length > 0) {
          this.currentFiscalMonth = this.getInventoryByThisMonth[0]?.[
            "year_month"
          ];
          field.value = parseInt(this.getInventoryByThisMonth[0]?.[field.name]);
        } else {
          field.value = "0";
        }
      });
    },
    patchEditPreFormValues() {
      this.fieldDisabled.forEach(field => {
        if (this.getInventoryByPreMonth.length > 0) {
          this.previousFiscalMonth = this.getInventoryByPreMonth[0]?.[
            "previous_year_month"
          ];
          field.value = this.getPriceFormat1(
            this.getInventoryByPreMonth[0]?.[field.name]
          );
        } else {
          field.value = "";
        }
      });
    },
    getPriceFormat1(amount) {
      return this.priceFormat(parseInt(amount));
    },
    getThisPriceFormat(amount, index) {
      if (amount != "") {
        this.fieldEnabled[index].value = new Intl.NumberFormat("ja-JP", {
          style: "currency",
          currency: "JPY"
        }).format(amount.replace(/[^0-9]/g, ""));
        this.fieldEnabled[index].value =
          this.fieldEnabled[index].value == "￥0"
            ? amount
            : this.fieldEnabled[index].value;
      } else {
        this.fieldEnabled[index].value = "";
      }
      return this.fieldEnabled[index].value;
    },
    getPrePriceFormat(amount, index) {
      if (amount != "") {
        this.fieldDisabled[index].value = new Intl.NumberFormat("ja-JP", {
          style: "currency",
          currency: "JPY"
        }).format(amount.replace(/[^0-9]/g, ""));
      } else {
        this.fieldDisabled[index].value = "";
      }
      return this.fieldDisabled[index].value;
    }
  },
  data() {
    return {
      loading: false,
      disabled: false,
      errors: null,
      invalid: null,
      pageLoading: false,
      yearMonth: null,
      previousFiscalMonth: null,
      currentFiscalMonth: null,
      fieldDisabled: [
        {
          label: "フード",
          name: "previous_this_month_food",
          type: "text",
          value: "",
          placeholder: "￥0,000,000",
          disabled: true
        },
        {
          label: "ドリンク",
          name: "previous_this_month_drink",
          type: "text",
          value: "",
          placeholder: "￥0,000,000",
          disabled: true
        }
      ],
      fieldEnabled: [
        {
          label: "フード",
          name: "this_month_food",
          type: "text",
          value: "",
          placeholder: "￥0,000,000",
          disabled: false,
          rules: "enter_half_width_amount"
        },
        {
          label: "ドリンク",
          name: "this_month_drink",
          type: "text",
          value: "",
          placeholder: "￥0,000,000",
          disabled: false,
          rules: "enter_half_width_amount"
        }
      ]
    };
  },
  created() {
    this.yearMonth = this.$route.query.year_month;
    this.getDataFromApi();
  }
};
</script>

<style lang="scss" src="./style.scss"></style>
